import React from 'react'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Container,
  Input,
  Row,
  Col,
  CardTitle,
  FormText,
  Label
} from 'reactstrap'
// redux
import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'
import Loader from '../../../components/Loader'
// core components
import IndexNavbar from 'components/Navbars/IndexNavbar.js'
import AuthFooter from 'components/Footers/AuthFooter.js'
// import {products as productDetails} from "../../../firebase/config.js";
// import Select2 from 'react-select2-wrapper'
import {
  stripePromise,
  firebaseAnalytics,
  firebaseAnalyticsEventName
} from '../../../firebase/firebase'
// import ReactPixel from 'react-facebook-pixel'
import { pixelId } from '../../../firebase/config'
import { us_states } from '../../../data/us_states'
import PhoneInput from 'components/Inputs/PhoneInput'
import { notificationError } from '../../../utils/notifications'

// ReactPixel.init(pixelId)

class CheckoutV2 extends React.Component {
  state = {
    checked: false
  }

  convertDateToServer = (data) => {
    const year = data.substr(0, 4)
    const month = data.substr(5, 2)
    const day = data.substr(8, 2)
    return month + '/' + day + '/' + year
  }

  showValueOrDefault = (defaul_avl, value) => {
    if (value) {
      return value
    } else if (defaul_avl) {
      return defaul_avl
    } else {
      return ''
    }
  }

  ageValidation(bod) {
    var dob = new Date(bod).getTime()
    var today = new Date().getTime()
    var age = (today - dob) / (365 * 24 * 60 * 60 * 1000)
    if (Math.floor(age) < 18) {
      return false
    }
    return true
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.checkoutSessionId &&
      this.props.checkoutSessionId !== prevProps.checkoutSessionId
    ) {
      const stripe = await stripePromise
      const sessionId = this.props.checkoutSessionId
      stripe.redirectToCheckout({ sessionId })
      ReactPixel.trackCustom('Purchase', {
        email: this.state.email,
        gender: this.state.gender,
        zipcode: this.state.postal_code
      })
      firebaseAnalytics.logEvent(firebaseAnalyticsEventName.PURCHASE, {
        email: this.state.email,
        gender: this.state.gender,
        zipcode: this.state.postal_code
      })
    }

    if (
      this.props.productDetails &&
      this.props.productDetails !== prevProps.productDetails
    ) {
      const productUrl = this.parseUrlParameter(this.props.match.params.id)
      const product = this.props.productDetails[productUrl[0]]
    /*   ReactPixel.trackCustom('startCheckout', {
        contents_ids: productUrl[0],
        contents: product.name,
        frequency: productUrl[1],
        num_items: 1
      }) */
      firebaseAnalytics.logEvent(firebaseAnalyticsEventName.BEGIN_CHECKOUT, {
        contents_ids: productUrl[0],
        contents: product.name,
        frequency: productUrl[1],
        num_items: 1
      })
      if (
        this.props.checkoutSessionError &&
        this.props.checkoutSessionError !== prevProps.checkoutSessionError
      ) {
        notificationError('Error to process your request.')
      }
      this.props.onSetAuthRedirectPath('/')
    }
  }

  goToStripePay = (price_id, product_id) => {
    let email = this.state.email
    let first_name = this.state.first_name
    let last_name = this.state.last_name
    let dob = this.state.date_of_birth
    let gender = this.state.gender
    let phone = this.state.phone

    let line1 = this.state.line1
    let line2 = this.state.line2 === null ? '' : this.state.line2
    let city = this.state.city
    let state = this.state.state
    let postal_code = this.state.postal_code

    if (
      !email ||
      email === '' ||
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      notificationError('Please provide valid email address.')
      return
    }
    if (!phone || phone.length !== 14) {
      notificationError(
        'Please provide valid US phone number with 10 digits.'
      )
      return
    }
    if (!first_name || first_name === '') {
      notificationError('Please provide first name.')
      return
    }
    if (!last_name || last_name === '') {
      notificationError('Please provide last name.')
      return
    }
    if (!dob || dob === '') {
      notificationError('Please provide date of birth.')
      return
    } else {
      if (!this.ageValidation(dob)) {
        notificationError(
          'Based on our policy, we only take blood test for whom is over 18 years old.'
        )
        return
      }
    }
    if (!gender || gender === '') {
      notificationError('Please choose gender.')
      return
    }
    if (!line1 || line1 === '') {
      notificationError('Please provide shipping address line1.')
      return
    }
    if (!city || city === '') {
      notificationError('Please provide shipping address city.')
      return
    }
    if (!state || state === '') {
      notificationError('Please provide shipping address state.')
      return
    }
    if (!postal_code || postal_code === '') {
      notificationError('Please provide shipping address Postal code.')
      return
    } else {
      if (postal_code.length !== 5) {
        notificationError('Postal code should be 5 digit.')
        return
      }
    }

    // at this point, everything looks good then call
    this.props.sendCheckoutForm(
      email,
      phone,
      first_name,
      last_name,
      product_id,
      price_id,
      gender,
      dob,
      line1,
      line2,
      city,
      state,
      postal_code
    )
  }

  onChange = (event) => {
    const {
      target: { name, value }
    } = event
    this.setState({ [name]: value })
  }

  formatPhone = (value) => {
    // return nothing if no value
    if (!value) return value

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '')
    const cvLength = currentValue.length

    if (!this.state.phone || value.length > this.state.phone.length) {
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`
    }
    return value
  }

  convertDateToInput = (data) => {
    if (data) {
      let month = data.substr(0, 2)
      let day = data.substr(3, 2)
      let year = data.substr(6, 4)
      return year + '-' + month + '-' + day
    }
    return ''
  }

  componentDidMount() {
    if (!this.props.loadSuccess) {
      this.props.getProductList()
    }
  }

  parseUrlParameter(url) {
    return url.split('&')
  }

  render() {
    if (this.props.isCheckoutSessionLoading || !this.props.loadSuccess) {
      return <Loader loading={true} />
    }

    let address = {
      line1: '',
      line2: '',
      city: '',
      postal_code: '',
      state: ''
    }

    const productUrl = this.parseUrlParameter(this.props.match.params.id)
    if (productUrl.length !== 2) {
      return (
        <>
          <IndexNavbar />
          <div className="content py-6 pb-9">
            <Container className="mt--6 text-center" fluid>
              <Card>
                <CardImg
                  alt="..."
                  src={require('assets/img/theme/nodata.jpg')}
                  center
                />
                <CardBody>
                  <CardTitle className="mb-3" tag="h3">
                    Whoops, no product for this checkout session.
                  </CardTitle>
                  <Button
                    color="primary"
                    href="https://www.labme.ai"
                    onclick="window.location='https://www.labme.ai'"
                  >
                    Find the product
                  </Button>
                </CardBody>
              </Card>
            </Container>
          </div>
          <AuthFooter />
        </>
      )
    }

    const product = this.props.productDetails[productUrl[0]] //["prod_I0pFSNvUoK3RX6"];
    const price = product.prices[productUrl[1]] //['monthly'];

    if (!product || !price) {
      return (
        <>
          <IndexNavbar />
          <div className="content py-6 pb-9">
            <Container className="mt--6 text-center" fluid>
              <Card>
                <CardImg
                  alt="..."
                  src={require('assets/img/theme/nodata.jpg')}
                  center
                />
                <CardBody>
                  <CardTitle className="mb-3" tag="h3">
                    Whoops, no product for this checkout session.
                  </CardTitle>
                  <Button
                    color="primary"
                    href="https://www.labme.ai"
                    onclick="window.location='https://www.labme.ai'"
                  >
                    Find the product
                  </Button>
                </CardBody>
              </Card>
            </Container>
          </div>
          <AuthFooter />
        </>
      )
    }

    return (
      <>
        <IndexNavbar />
        <div className="content">
          <section className="py-4 pb-8">
            <Container fluid>
              <Row className="justify-content-center text-center">
                <Col md="6">
                  <h3
                    className="display-4"
                    style={{
                      fontFamily: 'quicksand',
                      fontWeight: '700',
                      color: '#32325D'
                    }}
                  >
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    {' Secure Checkout'}
                  </h3>
                </Col>
              </Row>
            </Container>
          </section>
          <section
            className="section section-lg pt-lg-0 mt--7"
            style={{
              fontFamily: 'quicksand',
              fontWeight: '700',
              color: '#32325D'
            }}
          >
            <Container>
              <Row className="justify-content-center">
                <Col sm={{ size: 4, order: 2 }}>
                  <Card className="shadow border-0">
                    <CardBody
                      className="py-5"
                      style={{
                        fontFamily: 'quicksand',
                        fontWeight: '700',
                        color: '#32325D'
                      }}
                    >
                      <h4 className="h3">Your Test Package</h4>
                      <CardImg alt="..." src={product.imageUrl} top />
                      <p className="h4">{product.name}</p>
                      <Row>
                        <p className="text-info h3 ml-3">{price.price}</p>
                        <p className="h4 ml-1 text-muted">{price.name}</p>
                      </Row>
                      <Row>
                        <p className="h5 ml-3">
                          You can apply promotion code at the next step
                        </p>
                      </Row>

                      <p className="description mt-3">
                        <span class="btn-inner--icon text-blue">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="btn-inner--text">
                          {' 24/7 Customer Service'}
                        </span>
                      </p>
                      <p className="description mt-3">
                        <span class="btn-inner--icon text-blue">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="btn-inner--text">
                          {' Free Shipping Both Ways'}
                        </span>
                      </p>
                      <p className="description mt-3">
                        <span class="btn-inner--icon text-blue">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="btn-inner--text">
                          {' Secured Results & Transactions'}
                        </span>
                      </p>
                      <p className="description mt-3">
                        <span class="btn-inner--icon text-blue">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="btn-inner--text">
                          {' Track Weight, Blood Pressure & Gluecose Meter'}
                        </span>
                      </p>
                      <p className="description mt-3">
                        <span class="btn-inner--icon text-blue">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="btn-inner--text">
                          {
                            ' Flexible Planning (Change Frequency or Cancel At Anytime)'
                          }
                        </span>
                      </p>
                      <p className="description mt-3">
                        <span class="btn-inner--icon text-blue">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="btn-inner--text">
                          {' We use STRIPE payment processing'}
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                  <Card className="shadow border-0">
                    <CardBody className="py-3">
                      <Col className="col-auto">
                        <a
                          className="avatar avatar-xl rounded-circle"
                          href="#pablo"
                        >
                          <img
                            alt="..."
                            src={require('assets/img/theme/KatyClose.png')}
                          />
                        </a>
                      </Col>
                      <p className="description mt-2">
                        <p>Katy Close</p>
                        <span class="btn-inner--icon text-yellow">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                        </span>
                        <p className="description">
                          Lab me allowed me to reduce my risk of heart attack by
                          67% by spotting disease early on.
                        </p>
                      </p>
                      <Col className="col-auto">
                        <a
                          className="avatar avatar-xl rounded-circle"
                          href="#pablo"
                        >
                          <img
                            alt="..."
                            src={require('assets/img/theme/ShannaDove.png')}
                          />
                        </a>
                      </Col>
                      <p className="description mt-2">
                        <p>Shanna Dove</p>
                        <span class="btn-inner--icon text-yellow">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                        </span>
                        <p className="description">
                          Simple to perform and easy to understand results.
                          Great way to monitor your health over time without a
                          huge doctors bill.
                        </p>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={{ size: 8, order: 1 }}>
                  <Card className="shadow border-0">
                    <CardBody className="py-5">
                      <Row>
                        <h4 className="h3 mb-4 ml-4">
                          Check your details are correct so the lab can process
                          your tests.
                        </h4>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Email address
                                </label>
                                <Input
                                  value={this.state.email || ''}
                                  name="email"
                                  onChange={this.onChange}
                                  id="input-email"
                                  type="text"
                                />
                                <FormText color="muted">
                                  Important: the login account is associated
                                  with your email address.
                                </FormText>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Phone Number
                                </label>
                                <PhoneInput
                                  state={this.state.phone || ''}
                                  name="phone"
                                  onChange={this.onChange}
                                  id="input-phone"
                                  type="text"
                                  placeholder="(xxx) xxx-xxxx"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  First name
                                </label>
                                <Input
                                  id="input-first-name"
                                  value={this.state.first_name || ''}
                                  name="first_name"
                                  onChange={this.onChange}
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Last name
                                </label>
                                <Input
                                  value={this.state.last_name || ''}
                                  id="input-last-name"
                                  name="last_name"
                                  onChange={this.onChange}
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="example-date-input"
                                >
                                  Date Of Birth
                                </label>
                                <Input
                                  value={this.state.date_of_birth}
                                  name="date_of_birth"
                                  id="example-date-input"
                                  onChange={this.onChange}
                                  type="date"
                                />
                                <FormText color="muted">
                                  Important: all testers must be over 18 years
                                  old.
                                </FormText>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Gender
                                </label>
                               {/*  <Select2
                                  className="form-control"
                                  value={this.state.gender}
                                  options={{
                                    placeholder: ''
                                  }}
                                  name="gender"
                                  onChange={this.onChange}
                                  data={[
                                    { id: 'Male', text: 'Male' },
                                    { id: 'Female', text: 'Female' }
                                  ]}
                                /> */}
                                <FormText color="muted">
                                  We know that gender can be sensitive. Please
                                  select what you were genetically born as.
                                </FormText>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="shadow border-0">
                    <CardBody className="py-5">
                      <h4 className="h3 mb-4 ml-4">
                        Where would you like us to send your kit? (US only)
                      </h4>
                      <div className="pl-lg-4">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Address Line 1
                              </label>
                              <Input
                                value={this.state.line1 || ''}
                                name="line1"
                                onChange={this.onChange}
                                id="input-address"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Address Line 2 (optional)
                              </label>
                              <Input
                                value={this.state.line2 || ''}
                                name="line2"
                                onChange={this.onChange}
                                id="input-address"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-city"
                              >
                                City
                              </label>
                              <Input
                                value={this.state.city || ''}
                                name="city"
                                onChange={this.onChange}
                                id="input-city"
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-city"
                              >
                                State
                              </label>
                             {/*  <Select2
                                className="form-control"
                                value={this.showValueOrDefault(
                                  address.state,
                                  this.state.state
                                )}
                                name="state"
                                onChange={this.onChange}
                                options={{
                                  placeholder: ''
                                }}
                                data={us_states}
                              /> */}
                              <FormText color="muted">
                                New York State does not allow at home blood
                                test.
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                Postal code
                              </label>
                              <Input
                                id="input-postal-code"
                                value={this.state.postal_code || ''}
                                name="postal_code"
                                onChange={this.onChange}
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <Row className="justify-content-center">
                        <Col className="text-center px-lg-5 py-3" lg="12">
                          <Label check className="text-muted">
                            <Input
                              type="checkbox"
                              id="checkbox2"
                              onChange={(e) =>
                                this.setState({ checked: e.target.checked })
                              }
                            />
                            I confirm that I have read, consent and agree to{' '}
                            <a
                              href="https://www.labme.ai/terms-conditions/"
                              target="_blank"
                            >
                              LabMe's User Agreement
                            </a>{' '}
                            and{' '}
                            <a
                              href="https://www.labme.ai/privacy-policy/"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>{' '}
                          </Label>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col className="text-center" lg="12">
                          <Button
                            color="primary"
                            type="button"
                            disabled={!this.state.checked}
                            onClick={() =>
                              this.goToStripePay(price.id, product.id)
                            }
                          >
                            Go To Secured Payment
                          </Button>
                        </Col>
                      </Row>
                      <Row className="justify-content-center mt-3">
                        <p className="h4 text-muted">
                          <span class="btn-inner--icon text-info">
                            <i class="fas fa-lock"></i>
                          </span>
                          <span class="btn-inner--text">
                            {' Secure SSL Encrypted Payment'}
                          </span>
                        </p>
                      </Row>
                      <Row className="justify-content-center">
                        <span className="h6 text-muted">
                          * By clicking on the payment button, you agree to our{' '}
                          <a href="https://www.labme.ai/terms-and-conditions">
                            Terms of Services
                          </a>{' '}
                          and{' '}
                          <a href="https://www.labme.ai/privacy-policy/">
                            Privacy Policy
                          </a>
                          .
                        </span>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
        <AuthFooter />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isCheckoutSessionLoading: state.stripe.isLoading,
    checkoutSessionError: state.stripe.loadError,
    checkoutSessionId: state.stripe.checkout_session_id,
    productDetails: state.stripe.products,
    loadSuccess: state.stripe.loadSuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendCheckoutForm: (
      email,
      phone,
      first_name,
      last_name,
      product_id,
      price_id,
      gender,
      dob,
      line1,
      line2,
      city,
      state,
      zipcode
    ) =>
      dispatch(
        actions.sendCheckoutForm(
          email,
          phone,
          first_name,
          last_name,
          product_id,
          price_id,
          gender,
          dob,
          line1,
          line2,
          city,
          state,
          zipcode
        )
      ),
    onSetAuthRedirectPath: (path) =>
      dispatch(actions.setAuthRedirectPath(path)),
    getProductList: () => dispatch(actions.getProductList())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutV2)
