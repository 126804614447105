import * as actionTypes from './actionTypes'
import { myFirebase } from '../../firebase/firebase'
import { markers } from '../../data/markers'
export const OUT_OF_UPPER_RANGE: number = 9999999999
export const OUT_OF_LOWER_RANGE: number = -9999999999
export const TNP: number = -9999999998

const requestReport = () => {
  return {
    type: actionTypes.BLOOD_REPORT_REQUEST
  }
}

const getGender = (gender) => {
  const lower_gender = gender.toLowerCase()
  if (lower_gender === 'f' || lower_gender === 'female') {
    return 'female'
  } else {
    return 'male'
  }
}

const receiveReport = (report) => {
  if (!report) {
    return {
      type: actionTypes.BLOOD_REPORT_SUCCESS,
      raw_data: null,
      report: null
    }
  }

  const reconcil_report = []
  const gender = getGender(report.gender)

  for (let [key, marker] of Object.entries(markers)) {
    if (!marker || !marker.isEnable) {
      continue
    }

    let status = 'TNP'
    if (report[key]) {
      const value = report[key]
      if (value === OUT_OF_LOWER_RANGE) {
        status = 'OUT_OF_LOWER_RANGE'
      } else if (value === OUT_OF_UPPER_RANGE) {
        status = 'OUT_OF_UPPER_RANGE'
      } else if (value === TNP) {
        status = 'TNP'
      } else if (value < marker.range[gender].normal_min) {
        if (value < marker.range[gender].border_min) {
          status = 'RISK'
        } else {
          status = 'BORDERLINE'
        }
      } else if (value > marker.range[gender].normal_max) {
        if (value > marker.range[gender].border_max) {
          status = 'RISK'
        } else {
          status = 'BORDERLINE'
        }
      } else {
        status = 'NORMAL'
      }

      reconcil_report.push({
        ...marker,
        range: marker.range[gender],
        data: report[key],
        status: status
      })
    }
  }

  return {
    type: actionTypes.BLOOD_REPORT_SUCCESS,
    raw_data: report,
    report: reconcil_report
  }
}

const reportError = (error) => {
  return {
    type: actionTypes.BLOOD_REPORT_FAILURE,
    error: error
  }
}

export const getSharedReport = (report_uid) => (dispatch) => {
  dispatch(requestReport())
  let update_function = myFirebase
    .functions()
    .httpsCallable('getSharedReportById')
  update_function({ uid: report_uid })
    .then((result) => {
      dispatch(receiveReport(result.data))
    })
    .catch((error) => {
      console.log(error)
      dispatch(reportError(error.message))
    })
}

export const getReport = (report_id) => (dispatch) => {
  dispatch(requestReport())
  myFirebase
    .firestore()
    .collection('reports')
    .doc(report_id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        dispatch(receiveReport(doc.data()))
      } else {
        console.log('No data presented for ' + report_id)
        dispatch(reportError('No profile data exist for current user'))
      }
    })
    .catch((error) => {
      console.log(error)
      dispatch(reportError(error.message))
    })
}
