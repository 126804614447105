import React from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Spinner,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap'
// core components
import { Link } from 'react-router-dom'

import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom';
// import ReactBSAlert from 'react-bootstrap-sweetalert'
import { greetingTextGen } from '../../../variables/general'
import BeatLoader from 'react-spinners/BeatLoader'

class ResetPassword extends React.Component {
  state = {
    email: '',
    email_error: false,
    alert: null,
    redirect: false
  }

  hideAlert = () => {
    this.setState({ alert: null, redirect: true })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.error && this.props.error !== prevProps.error) {
     /*  this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: 'block', marginTop: '300px' }}
            title="Woops, something went wrong"
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize=""
          >
            {this.props.error}
          </ReactBSAlert>
        ),
        email: ''
      }) */
    }

    if (
      this.props.resetPasswordSuccess &&
      this.props.resetPasswordSuccess !== prevProps.resetPasswordSuccess
    ) {
     /*  this.setState({
        alert: (
          <ReactBSAlert
            info
            style={{ display: 'block', marginTop: '300px' }}
            title="Success"
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnBsStyle="info"
            confirmBtnText="Ok"
            btnSize=""
          >
            If there's an account associated to your email we'll send a recovery
            link.
          </ReactBSAlert>
        ),
        email: ''
      }) */
    }
  }

  onChange = (event) => {
    const {
      target: { name, value }
    } = event
    this.setState({
      [name]: value,
      [name + '_error']: false
    })
  }

  resetPassword = () => {
    if (this.state.email.length < 1) {
      this.setState({
        email_error: true
      })
    } else {
      this.props.resetPassword(this.state.email)
    }
  }
  componentDidMount() {
    this.setState({ greeting_text: greetingTextGen() })
  }

  render() {
    return (
      <>
        {this.state.alert}
        {this.state.redirect && <Navigate to="/auth/login" />}
        <Row>
          <Col
            xs="12"
            md="6"
            className="d-none d-md-block main-container-greeting"
            style={{
              backgroundSize: 'cover',
              backgroundImage: `url(${require('assets/img/theme/login-bg.png')})`
            }}
          >
            <div className="welcome-register-login">
              {this.state.greeting_text}
            </div>
          </Col>
          <Col xs="12" md="6" className="main-container-register">
            <Container className=" pb-6">
              <Row className="justify-content-center m-1 p-4 mb-title-pages">
                <a href="/">
                  <img
                    alt="..."
                    src={require('assets/img/brand/auth_logo.png')}
                  />
                </a>
              </Row>
              <Row className="justify-content-center">
                <Col md="10" xl="8">
                  <div className="mt-3">
                    <div className="text-left title-pages">Forget Password</div>
                    <div className="text-left sub-title-pages">
                      Please enter your email and we will send you the new
                      password
                    </div>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedEmail
                      })}
                    >
                      <div
                        className={
                          'input-title ' +
                          (this.state.email_error ? 'negative-text' : '')
                        }
                      >
                        Email
                      </div>
                      <InputGroup
                        className={
                          'input-group-merge input-group-alternative input-group-custom ' +
                          (this.state.email_error ? 'input-group-error' : '')
                        }
                      >
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                        <Input
                          className="input-custom"
                          placeholder="Email"
                          type="email"
                          name="email"
                          value={this.state.email}
                          onChange={this.onChange}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                      {this.state.email_error ? (
                        <div className="input-title negative-text">
                          Please enter the email address.
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="my-4 button-create-register"
                        color="info"
                        type="button"
                        onClick={this.resetPassword}
                        loading
                      >
                        {this.props.loading ? (
                          <BeatLoader
                            size={15}
                            color={'#FFFFFF'}
                            loading={true}
                          />
                        ) : (
                          'Reset Password'
                        )}
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.isResetPassword,
    error: state.auth.resetPasswordError,
    resetPasswordSuccess: state.auth.resetPasswordSuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (email) => dispatch(actions.resetPassword(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
