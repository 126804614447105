import React from 'react';
import { useLocation, Location } from 'react-router-dom';

// Define a generic type for the component's props
interface WithLocationProps {
  location?: Location;
}

// This function takes a component...
export default function withLocation<T extends WithLocationProps = WithLocationProps>(
  Component: React.ComponentType<T>
) {
  // ...and returns another component...
  const WithLocationComponent: React.FC<Omit<T, keyof WithLocationProps>> = (props) => {
    const location = useLocation();
    // ... that renders the wrapped component with the location prop
    return <Component {...(props as T)} location={location} />;
  };

  return WithLocationComponent;
}
