import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import routes from 'routes.js'

class AuthLayout extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    this.refs.mainContent.scrollTop = 0
    document.body.classList.add('bg-secondary')
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-secondary')
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.refs.mainContent.scrollTop = 0
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/auth') {
        const Component = prop.component; // Get the component for this route
        return (
          <Route
            key={key}
            path={prop.path}
            element={<Component />}
          />
        )
      } else {
        return null
      }
    })
  }
  render() {
    return (
      <>
        <div
          className="main-content"
          ref="mainContent"
          style={{ fontFamily: 'quicksand', overflow: 'hidden' }}
        >
          <Routes>
             {this.getRoutes(routes)}
            <Route path="*" element={<Navigate to="/auth/login" replace />} />
          </Routes>
        </div>
      </>
    )
  }
}

export default AuthLayout
