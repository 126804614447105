import React from 'react'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col,
  CardTitle
} from 'reactstrap'

// core components
import IndexNavbar from 'components/Navbars/IndexNavbar.js'
import AuthFooter from 'components/Footers/AuthFooter.js'
import { pixelId } from '../../../firebase/config'
// import ReactPixel from 'react-facebook-pixel'
import { firebaseAnalytics } from '../../../firebase/firebase'

// ReactPixel.init(pixelId)

class CheckoutSuccess extends React.Component {
  componentDidMount() {
    // ReactPixel.trackCustom('paymentSuccess', {})
    firebaseAnalytics.logEvent('paymentSuccess', {})
  }

  render() {
    return (
      <>
        <IndexNavbar />
        <div className="content py-6 pb-9">
          <Container className="mt--4 text-center" fluid>
            <Row className="card-wrapper">
              <Col sm="12" md={{ size: 4, offset: 4 }}>
                <Card>
                  <CardImg
                    alt="..."
                    src={require('assets/img/theme/paymentsuccess.png')}
                    center
                  />
                  <CardBody>
                    <CardTitle className="mb-3" tag="h3">
                      You will receive an email for your tracking number when
                      test kit get shipped.
                    </CardTitle>
                    <Button
                      color="primary"
                      href="https://dashboard.labme.ai"
                      onclick="window.location='https://dashboard.labme.ai"
                    >
                      Go To Dashboard
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    )
  }
}

export default CheckoutSuccess
